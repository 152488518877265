import React from 'react';
import styled from 'styled-components';

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: rgba(0, 0, 0, .5);
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const ModalContent = styled.div`
  position: relative;
  display: flex;
  max-width: 640px;
  max-height: calc(100vh - 40px);
  background-color: rgb(255, 255, 255);
  padding: 3%;
  flex-direction: column;
  border-radius: 5px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: rgba(0, 0, 0, .3);
  color: rgb(255, 255, 255);
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  padding: .25em .5em;
  border: none;
  border-radius: 100%;
  font-weight: bold;
  cursor: pointer;
`;

const H2 = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1em;
`;

export default class Popup extends React.PureComponent {
  state = { show: true };

  handleClick = () => {
    this.setState({ show: false }, () => {
      const date = new Date();
      date.setDate(new Date().getDate() + 24 * 60 * 60 * 1000);
      document.cookie = `popup=1;expires=${date.toUTCString()};path=/;${document.cookie}`;
    })
  };

  render() {
    if (!this.state.show) return null;

    return (
      <Modal>
        <ModalContent>
          <CloseButton onClick={this.handleClick}>X</CloseButton>
          <H2>{this.props.content.title}</H2>
          <div dangerouslySetInnerHTML={{ __html: this.props.content.body }}/>
        </ModalContent>
      </Modal>
    )
  }
}