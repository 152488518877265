import React from "react";
import Link from "next/link";
import PropTypes from "prop-types";
import styled from "styled-components";

import { withTranslation } from "../../i18n";

const StyledLink = styled.a``;

const Footer = ({ t }) => (
  <div className="footer">
    <div className="footer__top has-decor-bg has-gray-gradient section">
      <ul>
        <li>
          <Link href="kapcsolat">
            <StyledLink>{t("footer.customer-service")}</StyledLink>
          </Link>
        </li>

        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/static/files/aszf.pdf"
            title={t("footer.gtc")}
          >
            {t("footer.gtc")}
          </a>
        </li>

        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/static/files/adatkezelesi-nyilatkozat.pdf"
            title={t("footer.privacy-statement")}
          >
            {t("footer.privacy-statement")}
          </a>
        </li>

        <li>
          <Link href="gyakran-ismetelt-kerdesek">
            <StyledLink>{t("footer.customer-information")}</StyledLink>
          </Link>
        </li>

        <li>
          <a
            href="http://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf"
            target="_blank"
          >
            <img
              alt="SimplePay"
              height={62}
              src="https://meglepkek.hu/uploads/images/_cache/cib-cards_7cef2954401908f1c5c30b3e2f319271.png"
            />
          </a>
        </li>
      </ul>
    </div>
    <div className="footer__bottom">
      <p>&copy; {new Date().getFullYear()}. Szerelemlakat</p>
    </div>
  </div>
);

Footer.propTypes = {
  t: PropTypes.func.isRequired,
};

Footer.getInitialProps = () => ({ namespacesRequired: ["common"] });

export default withTranslation("common")(Footer);
