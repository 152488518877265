import { combineReducers } from 'redux';

import modalReducer from './ducks/modal';
import canvasObjectReducer from './ducks/canvasObject';
import orderFormReducer from './ducks/orderForm';

const createReducers = () => combineReducers({
  modal: modalReducer,
  canvasObject: canvasObjectReducer,
  orderForm: orderFormReducer,
});

export default createReducers;
