import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';

import createRootReducer from './rootReducer';

export default function configureStore(preloadedState) {
  const middlewares = [];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
  const composedEnhancers = composeEnhancers(middlewareEnhancer);

  const store = createStore(
    createRootReducer({}, preloadedState),
    preloadedState,
    composedEnhancers,
  );

  return store;
}
