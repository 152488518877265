/* eslint-disable global-require */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import NProgress from "nprogress";

import Modal from "components/Modal";
import Header from "components/Header";
import Footer from "components/Footer";

import { Router } from "../../routes";

import "../../static/scss/app.scss";

if (typeof window !== "undefined") {
  document.WebFont = require("webfontloader");
}

function Layout({ children }) {
  useEffect(() => {
    NProgress.configure({
      parent: "body",
      trickle: true,
      showSpinner: true,
    });

    Router.events.on("routeChangeStart", () => NProgress.start());
    Router.events.on("routeChangeComplete", () => NProgress.done());
    Router.events.on("hashChangeStart", () => NProgress.start());
    Router.events.on("hashChangeComplete", () => NProgress.done());
    Router.events.on("routeChangeError", () => NProgress.done());
  }, []);

  return (
    <StyledLayoyt>
      <Header />
      <div>{children}</div>
      <Footer />
      <Modal />
    </StyledLayoyt>
  );
}

const StyledLayoyt = styled.div`
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  min-width: 320px;
`;

Layout.propTypes = {
  children: PropTypes.node,
};

Layout.defaultProps = {
  children: null,
};

export default Layout;
