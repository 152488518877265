import React from 'react';
import App from 'next/app';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import withRedux from 'next-redux-wrapper';
import { PageTransition } from 'next-page-transitions';

import makeStore from 'store';

import Layout from 'components/Layout';
import Popup from 'components/Popup';

import editorTheme from 'static/styled/editor/theme.json';
import primaryTheme from 'static/styled/theme.json';
import GlobalStyle from 'static/styled/global';
import { appWithTranslation } from '../i18n';

import bugsnagClient from '../lib/bugsnag';
import Error from './_error';
import defaultFetch from "../utils/defaultFetch";

import get from 'lodash/get';
import cookies from 'next-cookies';

const ErrorBoundary = bugsnagClient.getPlugin('react');

require('es6-promise').polyfill();
require('isomorphic-fetch');

class AppWrapper extends App {
  static async getInitialProps({ Component, ctx }) {
    const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};
    const data = await defaultFetch(`${process.env.BACKEND_URL}/api/alert`).then(r => r.json());

    return {
      popup: {
        status: get(data, 'data[0].status', false),
        title: get(data, 'data[0].title', ''),
        body: get(data, 'data[0].body', ''),
        cookies: cookies(ctx)
      },
      pageProps
    };
  }

  render() {
    const {
      Component, pageProps, store, router, popup: { status, cookies, ...popup }
    } = this.props;

    const dark = {};
    const isDark = false;

    return (
      <ErrorBoundary FallbackComponent={Error}>
        <Provider store={store}>
          <ThemeProvider theme={{ editor: editorTheme, ...primaryTheme, ...dark }}>
            <Layout>
              {status && !(!!Number(get(cookies, 'popup', 0))) && <Popup content={popup} />}
              <GlobalStyle dark={isDark} />
              <PageTransition timeout={300} classNames="page-transition">
                <Component {...pageProps} reduxStore={store} key={router.route} />
              </PageTransition>
              <style jsx global>
                {`
                  .page-transition-enter {
                    opacity: 0;
                  }
                  .page-transition-enter-active {
                    opacity: 1;
                    transition: opacity 300ms;
                  }
                  .page-transition-exit {
                    opacity: 1;
                  }
                  .page-transition-exit-active {
                    opacity: 0;
                    transition: opacity 300ms;
                  }
                `}
              </style>
            </Layout>
          </ThemeProvider>
        </Provider>
      </ErrorBoundary>
    );
  }
}

export default withRedux(makeStore)(appWithTranslation(AppWrapper));
