import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { fabric } from "fabric";

import styled from "styled-components";

import { Box, Columns } from "react-bulma-components";

const ImageSelectModal = (props) => {
  const { closeModal, images, addImage, color } = props;
  const [localImages, setLocalImages] = useState([]);
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    if (!fetched) {
      fetch("api/files/get-file", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(images),
      })
        .then((resp) => resp.json())
        .then(({ data }) => {
          setLocalImages(data);
          setFetched(true);
        });
    }
  });

  const handleImageSelect = (img) => {
    fabric.loadSVGFromURL(img, (loadedImg) => {
      var loadedObjects = fabric.util.groupSVGElements(loadedImg);
      loadedObjects.set({
        left: 0,
        top: 0,
      });

      let defaultImgWidth = 100;

      if (document.outerWidth < 768) defaultImgWidth = 50;
      loadedObjects.scaleToWidth(defaultImgWidth);
      document.canvas.centerObject(loadedObjects);

      addImage(loadedObjects.toJSON());
      closeModal();
    });
  };

  console.log(color);

  return (
    <StyledBox>
      <Columns>
        {localImages.length > 0 &&
          localImages.map((image) => (
            <Columns.Column size={3} key={image}>
              <ImageBox
                src={image}
                onClick={() => handleImageSelect(image)}
                color={color}
              />
            </Columns.Column>
          ))}
      </Columns>
    </StyledBox>
  );
};

ImageSelectModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  addImage: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  color: PropTypes.string,
};

const StyledBox = styled(Box)`
  display: flex;
  width: 800px;
  max-width: 100%;
  position: relative;
  overflow: auto;

  > .columns {
    flex: 1;
  }
`;

const ImageBox = styled.div`
  padding-bottom: 60%;
  background-image: url("${(props) => props.src}");
  background-color: ${(props) => {
    switch (props.color) {
      default:
      case "black":
        return "#1D1D1D";
      case "red":
        return "#C31F29";
      case "blue":
        return "#335ACF";
    }
  }};
  background-size: auto 80%;
  background-position: center center;
  border-radius: 6px;
  background-repeat: no-repeat;
  border: 1px solid ${(props) => props.theme.editor.colors.icon};
  cursor: pointer;
  // filter: grayscale(100%);
  transition: ${(props) => props.theme.editor.transition.default};

  // &:hover {
  //   filter: grayscale(0);
  // }
`;

export default ImageSelectModal;
