import { createGlobalStyle } from 'styled-components';
import darkTheme from './theme-dark.json';
import lightTheme from './theme.json';


const GlobalStyle = createGlobalStyle`
  body {
    color: ${props => (props.dark ? darkTheme.text : lightTheme.text)};
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;

    strong {
      color: inherit;
    }
  }

  .fb-page, 
  .fb-page span, 
  .fb-page span iframe[style] { 
      width: 100% !important; 
  }
`;

export default GlobalStyle;
