import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Modal } from 'react-bulma-components';
import { modalActions } from 'store/ducks/modal';
import { imageSelect } from './ModalTypes';

const MODAL_TYPES = {
  imageSelect,
};

const ModalContainer = (props) => {
  const { modalType, modalProps, hideModal } = props;

  if (!modalType) {
    return null;
  }

  const SpecifiedModal = MODAL_TYPES[modalType];

  return (
    <Modal
      className="modal"
      show={!!modalType}
      onClose={hideModal}
    >
      <SpecifiedModal closeModal={hideModal} {...modalProps} />
    </Modal>
  );
};

ModalContainer.propTypes = {
  modalType: PropTypes.string,
  modalProps: PropTypes.shape({
    foo: PropTypes.func,
    bar: PropTypes.object,
    fooBar: PropTypes.string,
  }).isRequired,
  hideModal: PropTypes.func.isRequired,
};

ModalContainer.defaultProps = {
  modalType: '',
};

const mapStateToProps = state => ({
  ...state.modal,
});

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(modalActions.hideModal()),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default withConnect(ModalContainer);
