import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Router from "next/router";
import Link from "next/link";

import { Navbar, Container } from "react-bulma-components";
import { withTranslation } from "../../i18n";

// const StyledCartIcon = styled.i`
//   position: relative !important;
// `;

const StyledBurger = styled.div`
  outline: none !important;
`;

const Header = (props) => {
  const { t } = props;
  const [menuOpen, setMenuOpen] = useState(false);
  const [componentDidMount, setComponentDidMount] = useState(false);
  const [menuTransparent, setMenuTransparent] = useState(false);

  useEffect(() => {
    if (!componentDidMount) {
      const listenScroll = () => {
        if (document.scrollY < 30) {
          setMenuTransparent(true);
        } else {
          setMenuTransparent(false);
        }
      };

      Router.events.on("routeChangeComplete", () => {
        const { route } = Router.router;
        if (route === "/") {
          document.addEventListener("scroll", listenScroll);
          listenScroll();
        } else {
          document.removeEventListener("scroll", listenScroll);
          setMenuTransparent(false);
        }
      });

      if (document.location.pathname === "/") {
        listenScroll();
        document.addEventListener("scroll", listenScroll);
      }

      setComponentDidMount(true);
    }
  }, []);

  return (
    <div
      role="navigation"
      className={`navbar ${menuTransparent && "navbar--transparent"}`}
    >
      <Container>
        <Navbar.Brand>
          <Link href="/" as="/">
            <Navbar.Item>
              <img
                src={"../static/images/logo.png"}
                alt={t("lock")}
                width="275"
              />
            </Navbar.Item>
          </Link>
        </Navbar.Brand>
        <div className="hamburger">
          <StyledBurger
            onClick={() => setMenuOpen(!menuOpen)}
            role="button"
            tabIndex="0"
            aria-label="Menü"
            className={`navbar-burger ${menuOpen ? "is-active" : ""}`}
          >
            <span />
            <span />
            <span />
          </StyledBurger>
          <p>MENÜ</p>
        </div>
        <div className={`navbar-menu ${menuOpen ? "is-active" : ""}`}>
          <Navbar.Container>
            <Link href="/order" as="/lakat-megrendelese">
              <Navbar.Item
                className="underlined is-emphasized"
                title={t("menu.order")}
              >
                {t("menu.order")}
              </Navbar.Item>
            </Link>
            <Link href="/faq" as="/gyakran-ismetelt-kerdesek">
              <Navbar.Item className="underlined" title={t("menu.faq")}>
                {t("menu.faq")}
              </Navbar.Item>
            </Link>
            <Link href="/contact" as="/kapcsolat">
              <Navbar.Item className="underlined" title={t("menu.contact")}>
                {t("menu.contact")}
              </Navbar.Item>
            </Link>
          </Navbar.Container>
        </div>
      </Container>
    </div>
  );
};

Header.getInitialProps = () => ({ namespacesRequired: ["common"] });

Header.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation("common")(Header);
